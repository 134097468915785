import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import BackButtonHeader from "../../components/BackButtonHeader";
import "./projects.scss";

function CNNFace(): JSX.Element {
  return (
    <div className="project-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Eshed Margalit | CNNs for Face Classification</title>
        <html lang="en" />
      </Helmet>
      <BackButtonHeader title="CNNs for Face Classification" />

      <div className="project-info">
        <p>
          The increased use of convolutional neural networks for face
          recognition in science, governance, and broader society has created an
          acute need for methods that can show how these ’black box’ decisions
          are made. We applied the decompositional pixel-wise attribution method
          of layer-wise relevance propagation (LRP) to resolve the decisions of
          several classes of VGG-16 models trained for face recognition. We find
          that ImageNet- and VGGFace-trained models sample face information
          differently even as they achieve comparably high classification
          performance. We also evaluated model decision weighting against human
          measures of similarity, providing a novel framework for interpreting
          face recognition decisions across human and machine.
        </p>
        <StaticImage
          className="project-image"
          src="../../images/projectImages/cnn_face.png"
          alt="A colorful image synthesized to maximally excite part of an artifical neural network"
          placeholder="tracedSVG"
          height={250}
          width={250}
        />
      </div>

      <hr />

      <h3>Read the Preprint</h3>
      <a
        href="https://arxiv.org/abs/2006.16795"
        target="_blank"
        rel="noreferrer"
      >
        <div className="project-link">
          <div className="title">
            Crawford, J., Margalit, E., Grill-Spector, K., & Poltoratski, S.
            (2020). Validation and generalization of pixel-wise relevance in
            convolutional neural networks trained for face classification. arXiv
            preprint arXiv:2006.16795.
          </div>
        </div>
      </a>
    </div>
  );
}

export default CNNFace;
